import {Padding} from "../../padding/padding.styled";
import ContentContainer from "../../content-container/content-container";
import {Colors} from "../../../utils/colors";
import Heading3 from "../../heading-3/heading-3";
import * as React from "react";
import {TextContainer} from "./primary-title-subtitle-text-grid-block.styled";
import {Content} from "../../two-text-columns/two-text-columns.styled";
import Heading1 from "../../heading-1/heading-1";

interface IColumnBlock {
    title: string,
    subtitle: string,
    text: string,
}

interface IPrimaryTitleSubtitleTextGridBlock {
    column: IColumnBlock[]
}

const PrimaryTitleSubtitleTextGridBlock = (props: IPrimaryTitleSubtitleTextGridBlock) => {
    return (
        <Padding topLaptop={80} top={50} bottom={80}>
            <ContentContainer>
                <TextContainer>
                    {props.column && props.column.map((block) => {
                        return (
                            <div key={block.title}>
                                <Heading1 title={block.title} textColor={Colors.red}/>

                                <Padding top={10} bottom={20}>
                                    <Heading3 title={block.subtitle} textColor={Colors.red}/>
                                </Padding>

                                <Content color={Colors.grey}>{block.text}</Content>
                            </div>
                        )
                    })}
                </TextContainer>
            </ContentContainer>
        </Padding>
    )
}

export default PrimaryTitleSubtitleTextGridBlock
