import styled from "styled-components";
import {Colors} from "../../../utils/colors";
import {Breakpoints} from "../../../utils/breakpoints";
import {FontSizes} from "../../../utils/font-sizes";
import * as React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

export const ProjectTitleContainer = styled.div.attrs({
    className: "flex flex-col text-center items-center relative tablet:absolute"
})`
  z-index: 3;
  outline: 3px solid ${Colors.red};
  outline-offset: 0;
  background: ${Colors.white};
  padding: 15px 40px;
  margin-top: 35px;
  margin-bottom: 35px;
  color: ${Colors.red};

  @media ${Breakpoints.tablet} {
    margin-top: 20px;
    margin-bottom: 20px;
    outline-offset: -15px;
    padding: 30px 40px;
    left: 9%;
    min-width: 400px;
    margin-top: 250px;
  }
`

export const PageNavigationContainer = styled.div.attrs({
    className: "flex flex-row items-center justify-center relative tablet:absolute"
})`
  z-index: 3;
  margin-top: 0;
  margin-right: -1px;
  bottom: 0;
  cursor: default;
  background: ${Colors.white};
  align-items: center;
  height: 80px;

  @media ${Breakpoints.tablet} {
    right: 0;
    margin-top: 524px;
    width: 200px;
  }
`

export const TextImageContainer = styled.div.attrs({
    className: "relative"
})`
  @media ${Breakpoints.tablet} {
    height: 1000px;
  }
`

export const TriangleContainer = styled.div.attrs({
    className: "relative tablet:absolute"
})`
  @media ${Breakpoints.tablet} {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 500px 1200px 0 2px;
    border-color: ${Colors.white} transparent transparent transparent;
    margin-left: -3px;
    margin-top: -1px;
    z-index: 1;
  }
`

export const TextContainer = styled.div.attrs({
    className: "relative tablet:absolute"
})`
  z-index: 2;
  margin-right: 0;

  @media ${Breakpoints.laptop} {
    margin-right: 500px;
  }
`

export const DescriptionButtonContainer = styled.div.attrs({
    className: "w-full relative"
})`
  z-index: 0;
  overflow: visible;
  background: ${Colors.red};
`

export const DescriptionContainer = styled.h1`
  max-width: 700px;
  font-size: ${FontSizes.size48};
  line-height: 55px;
  color: ${Colors.white};
  font-weight: 600;
  margin-left: 50px;
  margin-right: 50px;

  @media ${Breakpoints.tablet} {
    font-size: ${FontSizes.size72};
    line-height: 80px;
  }

  @media ${Breakpoints.laptop} {
    margin-left: 115px;
    margin-right: 115px;
  }
`

export const ActionBorderButtonContainer = styled.div.attrs({
    className: "w-full"
})`
  @media ${Breakpoints.tablet} {
    width: 50%;
  }

  @media ${Breakpoints.laptop} {
    width: 100%;
  }
`

export const GatsbyImageContainer = styled(({...props}) => <GatsbyImage {...props} />).attrs({
    className: "tablet:h-full h-px-500"
})`
  position: relative;
`
