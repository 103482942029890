import styled from "styled-components";
import {FontSizes} from "../../../utils/font-sizes";
import {Colors} from "../../../utils/colors";

export const TextContainer = styled.div.attrs(() => ({
    className: 'grid grid-cols-1 gap-20 tablet:grid-cols-2 mt-20'
}))`
`

export const Content = styled.div<{ color: string }>`
  font-size: ${FontSizes.size16};
  line-height: 30px;
  color: ${props => props.color ? props.color : Colors.grey};
`
