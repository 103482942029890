import styled from "styled-components";
import {FontSizes} from "../../../utils/font-sizes";
import {Colors} from "../../../utils/colors";
import {Breakpoints} from "../../../utils/breakpoints";

export const TextContainer = styled.div.attrs(() => ({
    className: 'flex flex-row flex-wrap justify-center items-center'
}))`
    font-size: ${FontSizes.size14};
    line-height: 38px;
    font-weight: 500;
    color: ${Colors.red};
    white-space: pre;
    
    .fix-label & {
        top: 0;
        right: 0;
        left: 0;
        position: fixed;
        overflow-x: hidden;
        z-index: 1;
        background-color: ${Colors.white};
        border: 10px solid ${Colors.white};
    }
  }
  
  @media ${Breakpoints.tablet} {
    font-size: ${FontSizes.size22};
    line-height: 30px;
  }
`

export const TextPlaceholderContainer = styled.div.attrs(() => ({
    className: 'flex flex-row flex-wrap'
}))`
`

export const PlaceholderContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
`
