import styled from "styled-components";
import {Breakpoints} from "../../../utils/breakpoints";
import {Colors} from "../../../utils/colors";

export const ContactLocationEmail = styled.h1`
  font-size: 22px;
  color: ${Colors.greyDark};
  font-weight: bold;
  margin-top: 0;
  
  @media ${Breakpoints.tablet} {
    font-size: 30px;
  }
`
