export abstract class Mail {
    public static Link = (mail: string): string => {
        return "mailto:" + mail
    }
}

export abstract class MobileNumber {
    public static Number = (number: string): string => {
        return "tel:" + number.replace('(0)', '')
    }
}
