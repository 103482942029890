import styled from "styled-components";
import {FontSizes} from "../../utils/font-sizes";
import {Breakpoints} from "../../utils/breakpoints";

export const Title = styled.h1<{ textColor: string, textAlign: string, textAlignLaptop: string }>`
  font-size: ${FontSizes.size48};
  color: ${props => props.textColor};
  font-weight: 600;
  line-height: 0.8;
  text-align: ${props => props.textAlign};
  hyphens: none;

  @media ${Breakpoints.tablet} {
    font-size: ${FontSizes.size80};
  }

  @media ${Breakpoints.laptop} {
    text-align: ${props => props.textAlignLaptop};
    font-size: ${FontSizes.size80};
  }
`
