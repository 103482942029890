import * as React from "react";
import TwoTextColumns from "../../two-text-columns/two-text-columns";
import ContentContainer from "../../content-container/content-container";
import ActionButton from "../../action-button/action-button";
import {Padding} from "../../padding/padding.styled";
import TitleSubtitle from "../../title-subtitle/title-subtitle";
import {Colors} from "../../../utils/colors";
import Heading1 from "../../heading-1/heading-1";

interface ITextActionBlock {
    title: string,
    titleBig: string,
    subtitle: string,
    actionButtonTitle: string,
    actionButtonAction: string,
    actionButtonLink: string,
    firstColumn?: string,
    secondColumn?: string,
}

const TextActionBlock = (content: ITextActionBlock) => {
    
    return (
        <ContentContainer>
            <Padding topLaptop={100} top={50} bottom={50}>
                <ActionButton title={content.actionButtonTitle} action={content.actionButtonAction}
                              buttonLink={content.actionButtonLink}/>
            </Padding>

            <Padding rightLaptop={220} right={0}>
                {
                    content.titleBig &&
                    <span id={content.titleBig.replace(" ", "")}>
                        <Heading1 title={content.titleBig} textColor={Colors.greyDark}/>
                    </span>
                }
                <TitleSubtitle title={content.title ?? ''}
                               subTitle={content.subtitle}/>
                {
                    (content.firstColumn || content.secondColumn) &&
                    <Padding top={40} bottom={80}>
                        <TwoTextColumns
                            first={content.firstColumn ?? ''}
                            second={content.secondColumn ?? ''}/>
                    </Padding>
                }
            </Padding>
        </ContentContainer>
    )
}

export default TextActionBlock
