import * as React from "react"
import {Colors} from "../../utils/colors";
import {Title} from "./heading-1.styled";

export interface IHeading1 {
    title: string,
    textAlign?: string,
    textAlignLaptop?: string,
    textColor?: string
}

const Heading1 = (content: IHeading1) => {
    return (
        <Title
            textAlign={content.textAlign ?? 'start'}
            textAlignLaptop={content.textAlignLaptop ?? 'left'}
            textColor={content.textColor ?? Colors.white}
        >
            {content.title}
        </Title>
    )
}

export default Heading1
