import * as React from "react"
import {ClickArea, Column, Title} from "./action-border-button.styled";
import {Colors} from "../../utils/colors";

export interface IActionBorderButton {
    title: string
    buttonLink?: string
    color?: string,
    hoverColor?: string,
    hoverBackgroundColor?: string,
    alignButton?: string,
    alignButtonMobile?: string,
}

const ActionBorderButton = (content: IActionBorderButton) => {
    return (
        <Column alignItems={content.alignButton} alignItemsMobile={content.alignButtonMobile}>
            <ClickArea href={content.buttonLink ?? '#'}>
                <Title color={content.color ?? Colors.red}
                       hoverBackgroundColor={content.hoverBackgroundColor ?? Colors.red}
                       hoverColor={content.hoverColor ?? Colors.white}>{content.title}</Title>
            </ClickArea>
        </Column>
    )
}

export default ActionBorderButton
