import * as React from "react"
import {Padding} from "../padding/padding.styled";
import Heading2 from "../heading-2/heading-2";
import {Colors} from "../../utils/colors";
import {ArrowIconContainer, PageNavigationContainer} from "./page-navigation-control.styled";
import ArrowNextIcon from "/src/assets/svg/ic_arrow_next.svg";
import ArrowBackIcon from "/src/assets/svg/ic_arrow_back.svg";

export interface IPageNavigationControl {
    title: string,
    onNext: () => void,
    onBack: () => void,
    isCentered?: boolean
}

const PageNavigationControl = (content: IPageNavigationControl) => {
    return (
        <PageNavigationContainer isCentered={content.isCentered}>
            <ArrowIconContainer
                onClick={() => content.onBack()}>
                <ArrowBackIcon/>
            </ArrowIconContainer>

            <Padding left={10} right={10}>
                <Heading2 title={content.title} textColor={Colors.red}/>
            </Padding>

            <ArrowIconContainer
                onClick={() => content.onNext()}>
                <ArrowNextIcon/>
            </ArrowIconContainer>
        </PageNavigationContainer>
    )
}

export default PageNavigationControl
