import styled from "styled-components";
import {Colors} from "../../../utils/colors";

export const Description = styled.p`
  color: ${Colors.grey};
  line-height: 30px;

  a {
    color: ${Colors.red};
    font-weight: bold;
  }
`
