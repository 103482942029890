import * as React from "react";
import ContentContainer from "../../content-container/content-container";
import {Mail} from "../../../utils/phone-email-link";
import {Colors} from "../../../utils/colors";
import {Padding} from "../../padding/padding.styled";
import TitleBig from "../../title-big/title-big";
import {ContactLocationEmail} from "../contact-location/contact-location.styled";

interface IContactHeadingBlock {
    title: string,
    eMail: string
}

const ContactHeadingBlock = (content: IContactHeadingBlock) => {
    return (
        <ContentContainer>
            <Padding bottom={20} top={100}>
                <TitleBig title={content.title} textColor={Colors.greyDark} textAlign={'start'}/>
            </Padding>

            <a href={Mail.Link(content.eMail)}>
                <ContactLocationEmail>
                    {content.eMail}
                </ContactLocationEmail>
            </a>
        </ContentContainer>
    )
}

export default ContactHeadingBlock
