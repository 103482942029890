import styled from "styled-components";
import {Breakpoints} from "../../../utils/breakpoints";

export const RowTextIcon = styled.div.attrs({
    className: "flex flex-col laptop:flex-row"
})`
`

export const ImageSlogan = styled.div`
  width: 400px;
  display: none;

  @media ${Breakpoints.tablet} {
    display: block;
    margin: auto;
  }
`

export const MobileImageSlogan = styled.div`
  display: block;
  margin: auto;
  
  @media ${Breakpoints.tablet} {
    display: none;
  }
`

