import ContentContainer from "../../content-container/content-container";
import * as React from "react";
import {useEffect, useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import {IAllProjects} from "../single-project-block/single-project-block";
import {
    OverlayContainer,
    PaginationNavigationContainer,
    ProjectGridBackgroundImage,
    ProjectGridEntry,
    ProjectGridInformationContainer,
    ProjectLocation,
    ProjectsGrid
} from "./all-projects.styled";
import Heading2 from "../../heading-2/heading-2";
import {Colors} from "../../../utils/colors";
import Overlay from "/src/assets/svg/overlay_projekte.svg";
import {getImage} from "gatsby-plugin-image";
import {Padding} from "../../padding/padding.styled";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {sanitizeProjectsUrl} from "../../../utils/url-sanitizer";
import PageNavigationControl from "../../page-navigation-control/page-navigation-control";

const ALL_PROJECTS_QUERY = graphql`
 fragment Project on WpProject {
  id
  props {
    address
    location
    title
    mainImage {
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
  title
}

query ALL_PROJECTS_QUERY {
  allWpProject {
    nodes {
      ...Project
    }
  }
}
`

interface IAllProjectsBlock {
    itemsPerPage: number;
}

const AllProjectsBlock = ({itemsPerPage}: IAllProjectsBlock) => {
    const [paginationIndex, setPaginationIndex] = useState(0);
    const [numberOfPaginationTabs, setNumberOfPaginationTabs] = useState(0);
    const data: IAllProjects = useStaticQuery(ALL_PROJECTS_QUERY);
    const allProjects = data.allWpProject.nodes;
    const breakpoints = useBreakpoint();

    useEffect(() => {
        setNumberOfPaginationTabs(Math.ceil(allProjects.length / getItemsPerPage()));
        if (paginationIndex > numberOfPaginationTabs) {
            setPaginationIndex(0);
        }
    })

    function getItemsPerPage(): number {
        return breakpoints.mobile ? 1 : itemsPerPage;
    }

    function onPaginationNavigationPressed(isNext: boolean): void {
        if (isNext && paginationIndex + 1 < numberOfPaginationTabs) {
            setPaginationIndex(paginationIndex + 1)
            document.getElementById("projects")?.scrollIntoView();
        }
        if (!isNext && paginationIndex > 0) {
            setPaginationIndex(paginationIndex - 1)
            document.getElementById("projects")?.scrollIntoView();
        }
    }

    const ProjectsToRender = () => {
        return (
            <>
                {
                    allProjects && allProjects.map((project, index) => {
                        const image = getImage(project.props.mainImage.localFile)
                        if (index < (getItemsPerPage() * paginationIndex) + getItemsPerPage() && index >= getItemsPerPage() * paginationIndex) {
                            return (
                                <ProjectGridEntry key={project.props.title}>
                                    <a href={sanitizeProjectsUrl(`/projekte/${project.title}`)}>
                                        <ProjectGridBackgroundImage objectFit={"cover"} image={image}
                                                                    alt="project-image"/>
                                        <ProjectGridInformationContainer>
                                            <ProjectLocation>
                                                {project.props.location}
                                            </ProjectLocation>
                                            <Heading2 title={project.props.title} textColor={Colors.greyDark}
                                                      textAlign={"center"}/>
                                        </ProjectGridInformationContainer>
                                    </a>
                                </ProjectGridEntry>
                            )
                        }
                        return null
                    })
                }
            </>
        )
    }

    return (
        <Padding top={50}>
            <ContentContainer>
                <ProjectsGrid id={"projects"}>
                    <OverlayContainer>
                        <Overlay/>
                    </OverlayContainer>
                    <ProjectsToRender/>
                </ProjectsGrid>
                <PaginationNavigationContainer>
                    <PageNavigationControl
                        title={`${paginationIndex + 1}/${numberOfPaginationTabs}`}
                        isCentered={true}
                        onBack={() => onPaginationNavigationPressed(false)}
                        onNext={() => onPaginationNavigationPressed(true)}/>
                </PaginationNavigationContainer>
            </ContentContainer>
        </Padding>
    )
}

export default AllProjectsBlock
