import styled from "styled-components";
import {Breakpoints} from "../../../utils/breakpoints";
import {FontSizes} from "../../../utils/font-sizes";
import {Colors} from "../../../utils/colors";
import {Title} from "../../title-big/title-big.styled";

export const HeaderContainer = styled.div`
  width: 100%;
  z-index: 0;
  margin-top: -90px;
  padding-bottom: 90px;
  position: relative;
  overflow: visible;

  ${Title} {
    @media ${Breakpoints.laptop} {
      font-size: ${FontSizes.size100};
    }
  }
`

export const HeaderBackground = styled.div`
  background: ${Colors.red};
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: absolute;
  height: 100%;
  top: 0;
  z-index: -1;
`

export const LandingImage = styled.div`
  display: none;
  overflow: hidden;

  @media ${Breakpoints.laptop} {
    display: block;
    position: ${"absolute"};
    top: 0;
    right: 0;
    bottom: 0;
  }
`

export const LandingImageMobile = styled.div`
  display: block;

  @media ${Breakpoints.laptop} {
    display: none;
  }
`

export const SubTitle = styled.span`
  font-size: ${FontSizes.size16};
  color: ${Colors.white};
  letter-spacing: 0.4px;
  line-height: 32px;
`

export const SubTitlePadding = styled.div`
  padding-left: 20px;

  @media ${Breakpoints.laptop} {
    padding-left: 20%;
  }
`
