import styled from "styled-components";
import {ReactNode} from "react";
import {Breakpoints} from "../../utils/breakpoints";

export interface IGrid {
    cols?: number,
    colsTablet?: number,
    colsLaptop?: number,
    gap?: number,
    children: ReactNode
}

export const Grid = styled.div<IGrid>`
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, minmax(0, 1fr));
  gap: ${props => props.gap}px;
  
  @media ${Breakpoints.tablet} {
    grid-template-columns: repeat(${props => props.colsTablet ?? props.cols}, minmax(0, 1fr));
  }
  @media ${Breakpoints.laptop} {
    grid-template-columns: repeat(${props => props.colsLaptop ?? props.cols}, minmax(0, 1fr));
  }
`;
