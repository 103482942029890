import * as React from "react"
import {Padding} from "../padding/padding.styled";
import {Colors} from "../../utils/colors";
import Heading3 from "../heading-3/heading-3";
import Heading2 from "../heading-2/heading-2";

export interface ITitleSubtitle {
    title?: string,
    subTitle: string,
    titleColor?: string
}

const TitleSubtitle = (content: ITitleSubtitle) => {
    return (
        <>
            <Padding bottom={15}>
                <Heading3 title={content.title?.toUpperCase()} textColor={content.title ?? Colors.red}/>
            </Padding>
            <Heading2 title={content.subTitle} textColor={Colors.black}/>
        </>
    )
}

export default TitleSubtitle
