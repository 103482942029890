import styled from "styled-components";

export const RowTitleActionButton = styled.div.attrs({
    className: "flex flex-col tablet:flex-row justify-between"
})`
`

export const ActionButtonContainer = styled.div`
  max-width: 400px; 
  margin-top: 50px;
`

export const TextContainer = styled.div`
  max-width: 400px; 
`
