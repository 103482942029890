import * as React from "react"
import {Column, ImageContainer, OverlayContainer, TitleContainer} from "./title-image-block.styled";
import {Padding} from "../../padding/padding.styled";
import TitleBig from "../../title-big/title-big";
import {Colors} from "../../../utils/colors";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Heading1 from "../../heading-1/heading-1";
import Overlay from "/src/assets/svg/overlay_projekte_gespiegelt.svg";

export interface ITitleImageBlock {
    title: string,
    subtitle: string,
    image: {
        localFile: FileNode
    }
}

const TitleImageBlock = (props: ITitleImageBlock) => {
    const image = props.image !== null ? getImage(props.image.localFile) : null;

    return (
        <Padding rightLaptop={150} right={0} topLaptop={120} top={30}>
            <Column>
                <TitleContainer>
                    <Padding rightLaptop={150} right={0} bottom={20}>
                        <TitleBig title={props.title} textColor={Colors.greyDark} textAlign={'center'}
                                  textAlignLaptop={'end'}/>
                    </Padding>

                    <Padding rightLaptop={50} right={0}>
                        <Heading1 title={props.subtitle} textColor={Colors.greyDark} textAlignLaptop={'end'}
                                  textAlign={'center'}/>
                    </Padding>
                </TitleContainer>

                {
                    image &&
                    <ImageContainer>
                        <OverlayContainer>
                            <Overlay/>
                        </OverlayContainer>
                        <GatsbyImage image={image} alt="tbd"/>
                    </ImageContainer>
                }
            </Column>
        </Padding>
    )
}

export default TitleImageBlock
