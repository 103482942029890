import styled from "styled-components";
import {FontSizes} from "../../../utils/font-sizes";
import {Colors} from "../../../utils/colors";
import {Breakpoints} from "../../../utils/breakpoints";

export const HeaderContainer = styled.div<{ sameSizeForMobile: boolean }>`
  max-width: ${content => content.sameSizeForMobile ?  '100%' : '700px'};
  font-size: ${content => content.sameSizeForMobile ? FontSizes.size24 : FontSizes.size48};
  line-height: ${content => content.sameSizeForMobile ? 33 : 55}px;
  color: ${Colors.red};
  font-weight: 600;
  overflow-wrap: break-word;

  @media ${Breakpoints.tablet} {
    font-size: ${FontSizes.size72};
    line-height: 80px;
  }
`

export const SubheaderContainer = styled.h1`
  max-width: 700px;
  font-size: ${FontSizes.size16};
  line-height: 16px;
  margin-top: 15px;
  color: ${Colors.red};
`

export const ActionBorderButtonContainer = styled.div`
  width: 100%;

  @media ${Breakpoints.tablet} {
    width: 50%;
  }

  @media ${Breakpoints.laptop} {
    width: 100%;
  }
`

export const BackgroundContainer = styled.div<{ backgroundColor: string }>`
    background-color: ${content => content.backgroundColor};
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: absolute;
  height: 100%;
  z-index: -1;
`

export const BlockContainer = styled.div`
  width: 100%;
  z-index: 0;
  position: relative;
  overflow: visible;
`
