import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";
import * as React from "react";
import {Breakpoints} from "../../utils/breakpoints";

export const ColumnGrid = styled.div.attrs({
    className: "grid laptop:grid-cols-2 grid-cols-1 gap-12"
})`
  justify-items: center;
  align-items: center;
`

export const ColumnContainer = styled.div<{ leftColumn: boolean }>` 
  width: 100%;
  
  @media ${Breakpoints.tablet} {
      width: ${props => props.leftColumn ? 100 : 50}%;
  }

  @media ${Breakpoints.laptop} {
      width: 100%;
  }
`

export const GatsbyImageContainer = styled(({...props}) => <GatsbyImage {...props} />).attrs({
    className: "h-full"
})`
`
