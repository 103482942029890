import styled from "styled-components";
import {Colors} from "../../utils/colors";
import {Breakpoints} from "../../utils/breakpoints";

export const IconTextContainer = styled.div.attrs<{ cols: number }>(props => ({
    className: `grid gap-6 grid-cols-1 gap-y-12 tablet:grid-cols-2`
}))<{ cols: number }>`
  @media ${Breakpoints.laptop} {
    grid-template-columns: repeat(${props => props.cols}, minmax(0, 1fr));
  }
`

export const IconTextBackground = styled.div`
  background: ${Colors.greyBackground};
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;

  &:hover {
    .even, .odd {
      transition: all 300ms;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
    }
  }
`

export const IconTextBorder = styled.div`
  position: absolute;
  border: 3px solid ${Colors.red};
  z-index: -1;
  transition: all 300ms;
  left: 20px;
  right: 20px;

  &.even {
    bottom: 20px;
    top: -20px;
  }

  &.odd {
    bottom: 20px;
    top: -20px;
  }
}

@media ${Breakpoints.laptop} {
  &.odd {
    bottom: -20px;
    top: 20px;
  }
}
`

export const IconTextWrapper = styled.div.attrs({
    className: 'flex flex-col'
})`
  word-break: break-word;
`

export const IconTextContent = styled.div`
  z-index: 1;
  width: 65%;
  margin: auto;
  color: ${Colors.red};
`

export const IconTextImage = styled.img`
  width: 120px;
  height: auto;
  margin-bottom: 20px;
  
  @media ${Breakpoints.tablet} {
    margin-bottom: 40px;
  }
`

export const HeaderContainer = styled.div`
  max-width: 350px;
  margin-left: auto;
`
