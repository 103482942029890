import styled from "styled-components";
import {Colors} from "../../utils/colors";
import {FontSizes} from "../../utils/font-sizes";

export const Column = styled.div.attrs({
    className: "flex flex-col items-end"
})`
`

export const ActionRow = styled.div.attrs({
    className: "flex flex-row justify-end"
})`
`

export const Title = styled.div`
  color: ${Colors.red};
  font-size: ${FontSizes.size20};
  font-weight: 500;
`

export const ActionTitle = styled.div`
  color: ${Colors.red};
  font-size: ${FontSizes.size16};
  padding-right: 4px;

  // This is needed so the UI does not "Jump" when hovering
  border-bottom: 1px solid transparent;
`

export const ClickArea = styled.a`
  cursor: pointer;

  &:hover {
    ${ActionTitle} {
      border-bottom: 1px solid ${Colors.red};
    }
  }
`
