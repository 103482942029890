import styled from "styled-components";
import {Breakpoints} from "../../../utils/breakpoints";

export const Column = styled.div.attrs({
    className: "flex flex-col items-end"
})`
`

export const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;

  @media ${Breakpoints.laptop} {
    height: 580px;
  }
`

export const OverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 164px;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const TitleContainer = styled.div`
  width: 100%;
  
  @media ${Breakpoints.tablet} {
    text-align: unset;
  }
`
