import * as React from "react";
import {Padding} from "../../padding/padding.styled";
import {graphql, useStaticQuery} from "gatsby";
import {ArrowContainer, Footer, JobEntryRow, JobEntryTitle, Title} from "./open-positions-block.styled";
import ContentContainer from "../../content-container/content-container";
import Arrow from "/src/assets/svg/ic_arrow_next.svg";

const ALL_JOBS_QUERY = graphql`
query ALL_JOBS_QUERY {
  allWpJob {
    nodes {
      props {
        title
        pdf {
          localFile {
            publicURL
          }
        }
      }
    }
  }
}
`

interface IOpenPositionsBlock {
    title: string,
    titleEmpty: string,
    footer: string,
}

interface IAllJobs {
    allWpJob: {
        nodes: IJob[]
    }
}

interface IJob {
    props: {
        title: string,
        pdf: {
            localFile: {
                publicURL: string
            }
        }
    }
}

const JobEntry = (title: string, fileUrl: string) => {
    return (
        <a key={title} href={fileUrl} target="_blank">
            <Padding leftLaptop={20} rightLaptop={40} top={20} bottom={20}>
                <JobEntryRow>
                    <Padding right={20}>
                        <JobEntryTitle>{title}</JobEntryTitle>
                    </Padding>
                    <ArrowContainer>
                        <Arrow/>
                    </ArrowContainer>
                </JobEntryRow>
            </Padding>
            <hr/>
        </a>
    )
}

const OpenPositionsBlock = (content: IOpenPositionsBlock) => {
    const data: IAllJobs = useStaticQuery(ALL_JOBS_QUERY);
    const hasAvailableJobs = data.allWpJob.nodes.length > 0;

    return (
        <ContentContainer>
            <Padding top={50} topTablet={80} topLaptop={100} bottom={50} bottomLaptop={100}>
                <Padding bottom={20} leftLaptop={20}>
                    <Title>{hasAvailableJobs ? content.title : content.titleEmpty}</Title>
                </Padding>
                {
                    hasAvailableJobs &&
                    data.allWpJob.nodes.map(entry => JobEntry(entry.props.title, entry.props.pdf.localFile.publicURL))
                }
                {
                    hasAvailableJobs &&
                    <Padding leftLaptop={20} top={40}>
                        <Footer dangerouslySetInnerHTML={{__html: content.footer}}/>
                    </Padding>
                }
            </Padding>
        </ContentContainer>
    )
}

export default OpenPositionsBlock
