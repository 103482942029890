import * as React from "react"
import ArrowIcon from "/src/assets/svg/ic_more.svg";
import {ActionRow, ActionTitle, ClickArea, Column, Title} from "./action-button.styled";

export interface IActionButton {
    title?: string,
    action?: string,
    buttonLink?: string
}

const ActionButton = (content: IActionButton) => {
    return (
        <>
            {
                content.action?.trim() !== "" &&
                <Column>
                    <ClickArea href={content.buttonLink}>
                        <Title>{content.title?.toUpperCase()}</Title>
                        <ActionRow>
                            <ActionTitle>{content.action}</ActionTitle>
                            <ArrowIcon/>
                        </ActionRow>
                    </ClickArea>
                </Column>
            }
        </>
    )
}

export default ActionButton
