import ContentContainer from "../../content-container/content-container";
import {Padding} from "../../padding/padding.styled";
import ActionButton from "../../action-button/action-button";
import * as React from "react";
import TitleSubtitle from "../../title-subtitle/title-subtitle";
import ActionBorderButton from "../../action-border-button/action-border-button";
import {ActionButtonContainer, RowTitleActionButton, TextContainer} from "./page-header-action-block.styled";
import {Colors} from "../../../utils/colors";
import TitleBig from "../../title-big/title-big";

interface IPageHeaderActionBlock {
    pageHeaderTitle: string,
    title: string,
    subtitle: string,
    actionButtonTitle: string,
    actionButtonAction: string,
    actionButtonLink: string,
    actionBorderButtonTitle: string,
    actionBorderButtonLink: string
}

const PageHeaderActionBlock = (content: IPageHeaderActionBlock) => {

    return (
        <ContentContainer>
            <Padding topLaptop={120} bottomLaptop={120} bottom={60} top={60}>
                <TitleBig title={content.pageHeaderTitle} textColor={Colors.greyDark} textAlign={'start'}/>
            </Padding>

            <RowTitleActionButton>
                <TextContainer>
                    <TitleSubtitle title={content.title}
                                   titleColor={Colors.black}
                                   subTitle={content.subtitle}/>
                    <Padding top={50}>
                        <ActionBorderButton title={content.actionBorderButtonTitle}
                                            alignButton={"flex-start"}
                                            alignButtonMobile={"stretch"}
                                            buttonLink={content.actionBorderButtonLink}/>
                    </Padding>
                </TextContainer>
                <ActionButtonContainer>
                    <ActionButton title={content.actionButtonTitle} action={content.actionButtonAction}
                                  buttonLink={content.actionButtonLink}/>
                </ActionButtonContainer>
            </RowTitleActionButton>
        </ContentContainer>
    )
}

export default PageHeaderActionBlock
