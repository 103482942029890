import * as React from "react";
import IconText, {IIconTextBlocks} from "../../icon-text/icon-text";
import {Padding} from "../../padding/padding.styled";

const IconTextBlock = (content: IIconTextBlocks) => {
    return (
        <>
            <Padding top={40} bottom={40}>
                <IconText heading={content.heading} iconTextBlocks={content.iconTextBlocks}/>
            </Padding>
        </>
    )
}

export default IconTextBlock
