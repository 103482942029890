import * as React from "react";
import ContentContainer from "../../content-container/content-container";
import Contact from "../../contact/contact";
import {Colors} from "../../../utils/colors";
import Heading1 from "../../heading-1/heading-1";
import {Padding} from "../../padding/padding.styled";
import {Grid} from "../../grid/grid.styled";

interface IContactLocationBlock {
    address: string,
    location: string,
    department: IDepartmentBlock[]
}

interface IDepartmentBlock {
    departmentName: string,
    mobileNumber: string,
    eMail: string,
}

const ContactLocationBlock = (content: IContactLocationBlock) => {
    return (
        <ContentContainer>
            <Padding top={100}>
                <Heading1 title={content.address} textColor={Colors.black}/>
                <Heading1 title={content.location} textColor={Colors.black}/>

                <Grid colsLaptop={3} colsTablet={2} cols={1} gap={12}>
                    {content.department && content.department.map((block, index) => {
                        return (
                            <div key={index}>
                                <Padding top={40}>
                                    <Contact
                                        eMail={block.eMail}
                                        job={block.departmentName}
                                        mobileNumber={block.mobileNumber}/>
                                </Padding>
                            </div>
                        )
                    })}
                </Grid>
            </Padding>
        </ContentContainer>
    )
}

export default ContactLocationBlock
