import * as React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {Padding} from "../../padding/padding.styled";

export interface IImageBlock {
    image: {
        localFile: FileNode
    }
}

const ImageBlock = (content: IImageBlock) => {
    const image = getImage(content.image.localFile)
    return (

            image &&
            <Padding >
                <GatsbyImage image={image} alt="tbd"/>
            </Padding>
    )
}

export default ImageBlock
