import * as React from "react";
import ContentContainer from "../../content-container/content-container";
import ActionButton from "../../action-button/action-button";
import {Padding} from "../../padding/padding.styled";
import TitleSubtitle from "../../title-subtitle/title-subtitle";
import ActionBorderButton from "../../action-border-button/action-border-button";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {ImageSlogan, MobileImageSlogan, RowTextIcon} from "./text-action-button-image-block.styled";

interface ITextActionButtonImageBlockProps {
    title: string,
    subtitle: string,
    actionButtonTitle: string,
    actionBorderButtonTitle: string,
    actionButtonAction: string,
    actionButtonLink: string,
    actionBorderButtonLink: string,
    image: {
        localFile: FileNode
    }
}

const TextActionButtonImageBlock = (props: ITextActionButtonImageBlockProps) => {
    const image = getImage(props.image.localFile)
    const url = typeof window !== 'undefined' ? window.location.origin : '';
    return (
        <ContentContainer>
            <Padding topLaptop={100} top={50} bottom={40}>
                <ActionButton title={props.actionButtonTitle} action={props.actionButtonAction}
                              buttonLink={`${url}${props.actionButtonLink}`}/>
            </Padding>

            <RowTextIcon>
                <Padding rightLaptop={100} right={0}>
                    <TitleSubtitle title={props.title}
                                   subTitle={props.subtitle}/>
                    <Padding top={50}>
                        <ActionBorderButton title={props.actionBorderButtonTitle}
                                            alignButton={"flex-start"}
                                            alignButtonMobile={"stretch"}
                                            buttonLink={props.actionBorderButtonLink}/>
                    </Padding>
                </Padding>

                <Padding top={50}>
                    {
                        image &&
                        <ImageSlogan>
                            <GatsbyImage image={image} alt="slogan-text"/>
                        </ImageSlogan>
                    }
                </Padding>
            </RowTextIcon>

            <Padding top={50} topLaptop={0}>
                {
                    image &&
                    <MobileImageSlogan>
                        <GatsbyImage image={image} alt="slogan-text-mobile"/>
                    </MobileImageSlogan>
                }
            </Padding>
        </ContentContainer>
    )
}

export default TextActionButtonImageBlock
