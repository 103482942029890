import * as React from "react"
import {IBlock} from "../templates/page";
import LandingTitleHeaderBlock from "./blocks/landing-title-header-block/landing-title-header-block";
import TextActionButtonImageBlock from "./blocks/text-action-button-image-block/text-action-button-image-block";
import IconTextBlock from "./blocks/icon-text-block/icon-text-block";
import AppStoreInfoBlock from "./blocks/app-store-info-block/app-store-info-block";
import TextActionBlock from "./blocks/text-action-block/text-action-block";
import ImageBlock from "./blocks/image-block/image-block";
import PageHeaderActionBlock from "./blocks/page-header-action-block/page-header-action-block";
import TitleActionBlock from "./blocks/title-action-block/title-action-block";
import TitleSubtitleGridBlock from "./blocks/title-subtile-grid-block/title-subtitle-grid-block";
import AnchorNavigationBlock from "./blocks/anchor-navigation-block/anchor-navigation-block";
import EmployeeBlock from "./blocks/employee-block/employee-block";
import PrimaryTitleSubtitleTextGridBlock
    from "./blocks/primary-title-subtitle-text-grid-block/primary-title-subtitle-text-grid-block";
import SingleProjectBlock from "./blocks/single-project-block/single-project-block";
import AllProjectsBlock from "./blocks/all-projects-block/all-projects-block";
import TitleImageBlock from "./blocks/title-image-block/title-image-block";
import ImageCarouselBlock from "./blocks/image-carousel-block/image-carousel-block";
import ImageTitleActionBlock from "./blocks/image-title-action-block/image-title-action-block";
import TitleGridBlock from "./blocks/title-grid-block/title-grid-block";
import ContactHeadingBlock from "./blocks/contact-heading/contact-heading";
import ContactLocationBlock from "./blocks/contact-location/contact-location";
import OpenPositionsBlock from "./blocks/open-positions-block/open-positions-block";

const components: { [key: string]: any } = {
    'landing-title-header': LandingTitleHeaderBlock,
    'text-action': TextActionBlock,
    'image': ImageBlock,
    'text-action-button-image': TextActionButtonImageBlock,
    'icon-text': IconTextBlock,
    'app-store-info': AppStoreInfoBlock,
    'page-header-action': PageHeaderActionBlock,
    'title-action': TitleActionBlock,
    'title-subtitle-grid': TitleSubtitleGridBlock,
    'anchor-navigation': AnchorNavigationBlock,
    'employee': EmployeeBlock,
    'primary-title-subtitle-text-grid': PrimaryTitleSubtitleTextGridBlock,
    'single-project': SingleProjectBlock,
    'all-projects': AllProjectsBlock,
    'title-image': TitleImageBlock,
    'image-carousel': ImageCarouselBlock,
    'image-title-action': ImageTitleActionBlock,
    'title-grid': TitleGridBlock,
    'contact-heading': ContactHeadingBlock,
    'contact-location': ContactLocationBlock,
    'open-positions': OpenPositionsBlock
}

const isEmpty = (obj: Object) => {
    return Object.entries(obj).length === 0 && obj.constructor === Object
}

const convertACFProps = (component: any) => {
    Object.keys(component).forEach(key => {
        if (key.includes("fields")) {
            component.data = component[key]
            delete component[key]
        }
    })

    return component
}

const sanitizeName = (name: string): string => {
    let newName = name
    newName = newName.replace("core/", "")
    newName = newName.replace("acf/", "")
    return newName
}

interface IBlockParserProps {
    blocks: [IBlock]
}

const BlockParser = (content: IBlockParserProps) => {
    return (
        <>
            {content.blocks && content.blocks.map((component, index) => {
                if (isEmpty(component) || !component.name) return null
                const componentName = sanitizeName(component.name)
                const BlockComponent = components[componentName]
                component = convertACFProps(component)
                return (
                    <BlockComponent
                        index={index}
                        key={`${component.name}-${index}`}
                        {...component.data}
                    />
                );
            })}
        </>
    )
}

export default BlockParser
