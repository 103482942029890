import styled from "styled-components";
import {Colors} from "../../../utils/colors";
import {Breakpoints} from "../../../utils/breakpoints";

export const Row = styled.div.attrs({
    className: "flex justify-between flex-col laptop:flex-row"
})`
`

export const TitleActionContainer = styled.div.attrs({
    className: "flex-1"
})`
`

export const GreyBackground = styled.div`
  background-color: ${Colors.greyBackground};
`

export const ImageContainer = styled.div.attrs({
    className: "flex-1"
})`
  max-width: 400px;
  margin: auto;

  @media ${Breakpoints.laptop} {
    max-width: unset;
    margin: unset;
  }
`
