import styled from "styled-components";
import {FontSizes} from "../../utils/font-sizes";
import {Breakpoints} from "../../utils/breakpoints";

export const Title = styled.h1<{ textAlign: string, textAlignLaptop: string, textColor: string }>`
  text-align: ${props => props.textAlign};
  font-size: ${FontSizes.size30};
  color: ${props => props.textColor};
  font-weight: 600;
  line-height: 32px;

  @media ${Breakpoints.laptop} {
    text-align: ${props => props.textAlignLaptop};
  }
`
