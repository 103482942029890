import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";
import * as React from "react";
import {Colors} from "../../../utils/colors";
import {FontSizes} from "../../../utils/font-sizes";
import {Breakpoints} from "../../../utils/breakpoints";

export const ProjectGridEntry = styled.div.attrs({
    className: "relative"
})`
  min-height: 350px;
  
  @media ${Breakpoints.laptop} {
    min-height: 500px;
  }
`

export const ProjectsGrid = styled.div.attrs({
    className: "relative grid grid-cols-1 gap-y-20 gap-x-10 tablet:grid-cols-2"
})`
`

export const ProjectGridInformationContainer = styled.div.attrs({
    className: "text-center absolute"
})`
  padding: 24px;
  background: ${Colors.white};
  bottom: -44px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  h2 {
    line-height: 22px;
  }
`

export const ProjectGridBackgroundImage = styled(({...props}) => <GatsbyImage {...props} />).attrs({
    className: "h-full"
})`
    position: unset;
`

export const PaginationNavigationContainer = styled.div.attrs({
    className: "flex flex-row items-center justify-center relative"
})`
  cursor: default;
  margin-top: 100px;
  background: ${Colors.white};

  @media ${Breakpoints.tablet} {
    margin-top: 150px;
  }
`

export const OverlayContainer = styled.div.attrs({
    className: "absolute w-full"
})`
  display: none;
  height: 164px;
  left: -1px;
  top: -1px;
  z-index: 1;
  pointer-events: none;

  svg {
    width: 105%;
    height: 100%;
  }
  
  @media ${Breakpoints.tablet} {
    display: block;
  }
`

export const ProjectLocation = styled.p`
  color: ${Colors.greyDark};
  font-size: ${FontSizes.size12};
  line-height: 18px;
`
