import * as React from "react";
import {useState} from "react";
import ContentContainer from "../../content-container/content-container";
import {Padding} from "../../padding/padding.styled";
import ActionButton from "../../action-button/action-button";
import TitleSubtitle from "../../title-subtitle/title-subtitle";
import {
    ActionBorderButtonContainer,
    DescriptionButtonContainer,
    DescriptionContainer,
    GatsbyImageContainer,
    ProjectTitleContainer,
    TextContainer,
    TextImageContainer,
    TriangleContainer
} from "./single-project-block.styled";
import ActionBorderButton from "../../action-border-button/action-border-button";
import {Colors} from "../../../utils/colors";
import {graphql, StaticQuery} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import Heading1 from "../../heading-1/heading-1";
import PageNavigationControl from "../../page-navigation-control/page-navigation-control";

const SINGLE_PROJECTS_QUERY = graphql`
 fragment Project on WpProject {
  id
  props {
    address
    location
    title
    mainImage {
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
  title
}

query SINGLE_PROJECTS_QUERY {
  allWpProject {
    nodes {
      ...Project
    }
  }
}
`

export interface IAllProjects {
    allWpProject: {
        nodes: [
            IProject
        ]
    }
}

export interface IProject {
    props: {
        address: string,
        location: string,
        title: string,
        mainImage: {
            localFile: FileNode
        }
    }
    title: string
}

interface ISingleProjectBlockProps {
    title: string,
    subtitle: string,
    actionButtonTitle: string,
    actionButtonAction: string,
    description: string,
    buttonLink: string,
    borderButtonText: string,
    borderButtonLink: string
}


const SingleProjectBlock = (props: ISingleProjectBlockProps) => {
    const [pageIndex, setPageIndex] = useState(0);

    function handlePageIndex(pageIndex: number, projectCount: number, isNext: boolean): void {
        if (isNext && pageIndex + 1 < projectCount) {
            setPageIndex(pageIndex + 1)
        }
        if (!isNext && pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    return (
        <ContentContainer onlyForMobile={true}>
            <TextImageContainer>
                <ContentContainer>
                    <Padding top={100} bottom={40}>
                        <ActionButton title={props.actionButtonTitle} action={props.actionButtonAction}
                                      buttonLink={props.buttonLink}/>
                    </Padding>
                </ContentContainer>
                <StaticQuery
                    query={SINGLE_PROJECTS_QUERY}
                    render={(data: IAllProjects) => {
                        const projectCount = data.allWpProject.nodes.length
                        if (data.allWpProject.nodes[pageIndex].props) {
                            const project = data.allWpProject.nodes[pageIndex];
                            const image = getImage(project.props.mainImage.localFile)
                            return (
                                image &&
                                <>
                                    <a href={`projekte/${project.title.toLowerCase().replace(" ", "_")}`}>
                                        <ContentContainer>
                                            <TextContainer>
                                                <TitleSubtitle title={props.title}
                                                               subTitle={props.subtitle}/>
                                            </TextContainer>
                                        </ContentContainer>
                                        <ProjectTitleContainer>
                                            <p>
                                                {project.props.location}
                                            </p>
                                            <Heading1 title={project.props.address} textColor={Colors.red}
                                                      textAlign={"center"}/>
                                        </ProjectTitleContainer>
                                        <TriangleContainer/>
                                        <GatsbyImageContainer image={image} alt="project-image"/>
                                    </a>

                                    <PageNavigationControl
                                        title={`${pageIndex + 1}/${projectCount}`}
                                        onBack={() => handlePageIndex(pageIndex, projectCount, false)}
                                        onNext={() => handlePageIndex(pageIndex, projectCount, true)}
                                    />
                                </>
                            )
                        }
                        return null
                    }}
                />
            </TextImageContainer>

            <DescriptionButtonContainer>
                <Padding topLaptop={80} top={30}>
                    <DescriptionContainer>
                        {props.description}
                    </DescriptionContainer>
                </Padding>

                <Padding topLaptop={80} leftLaptop={115} left={50} top={50} rightLaptop={600} right={50}
                         bottomLaptop={80}
                         bottom={40}>
                    <ActionBorderButtonContainer>
                        <ActionBorderButton title={props.borderButtonText}
                                            color={Colors.white}
                                            alignButtonMobile={"stretch"}
                                            alignButton={"stretch"}
                                            buttonLink={props.borderButtonLink}/>
                    </ActionBorderButtonContainer>
                </Padding>
            </DescriptionButtonContainer>
        </ContentContainer>
    )
}

export default SingleProjectBlock
