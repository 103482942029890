import * as React from "react"
import {Padding} from "../../padding/padding.styled";
import ContentContainer from "../../content-container/content-container";
import Heading3 from "../../heading-3/heading-3";
import {Colors} from "../../../utils/colors";
import {Description} from "./title-grid-block.styled";
import {Grid} from "../../grid/grid.styled";

interface ITitleGridBlock {
    title: string,
    text: string
}

interface ITitleGridBlocks {
    titleGridBlocks: ITitleGridBlock[]
}

const TitleGridBlock = (content: ITitleGridBlocks) => {
    return (
        <Padding rightLaptop={150} right={0} topLaptop={150} top={40} leftLaptop={120} left={0}>
            <ContentContainer>
                <Grid cols={1} colsLaptop={2}>
                    {content.titleGridBlocks && content.titleGridBlocks.map((block) => {
                        return (
                            <Padding bottomLaptop={80} bottom={40} rightLaptop={40} right={0} key={block.title}>
                                <Heading3 title={block.title.toUpperCase()} textColor={Colors.greyDark}/>
                                <Padding top={10}>
                                    <Description dangerouslySetInnerHTML={{__html: block.text}}/>
                                </Padding>
                            </Padding>
                        )
                    })}
                </Grid>
            </ContentContainer>
        </Padding>
    )
}

export default TitleGridBlock
