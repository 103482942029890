import * as React from "react";
import ContentContainer from "../../content-container/content-container";
import {graphql, useStaticQuery} from "gatsby";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {Padding} from "../../padding/padding.styled";
import Heading1 from "../../heading-1/heading-1";
import {Colors} from "../../../utils/colors";
import ContactWithDescription from "../../contact-with-description/contact-with-description";
import Contact from "../../contact/contact";
import {EmployeeColumnContainer} from "./employee.styled";
import {Grid} from "../../grid/grid.styled";

const ALL_EMPLOYEE_QUERY = graphql`
  fragment Employee on WpEmployee {
  id
  props {
    eMail
    job
    mobileNumber
    name
    role
    description
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
}

query ALL_EMPLOYEE_QUERY {
  allWpEmployee {
    nodes {
      ...Employee
    }
  }
}
`

interface IEmployee {
    props: {
        eMail: string
        job: string,
        mobileNumber: string,
        name: string,
        role: string,
        description: string
        image: {
            localFile: FileNode
        }
    }
}

export interface IAllEmployees {
    allWpEmployee: {
        nodes: [
            IEmployee
        ]
    }
}

interface IEmployeeBlock {
    title?: string,
    role: string
}

const EmployeeBlock = (content: IEmployeeBlock) => {
    const data: IAllEmployees = useStaticQuery(ALL_EMPLOYEE_QUERY);
    const allEmployee = data.allWpEmployee.nodes

    let colsLaptop = 0;
    let colsTablet = 1;
    const specificEmployeeGroup = allEmployee?.filter((employee) => employee.props.role == content.role)

    if (specificEmployeeGroup) {
        if (specificEmployeeGroup[0].props.description) {
            colsLaptop = 1;
        } else {
            colsTablet = 2;
            if (specificEmployeeGroup[0].props.image) {
                colsLaptop = 2;
            } else {
                colsLaptop = 3;
            }
        }
    }

    const EmployeeToRender = () => {
        if (allEmployee) {
            return (
                <>
                    {
                        specificEmployeeGroup && specificEmployeeGroup.map((employee, index) => {
                            if (employee.props.description) {
                                return (
                                    <Padding key={employee.props.name} bottom={120}>
                                        <ContactWithDescription
                                            name={employee.props.name}
                                            description={employee.props.description}
                                            eMail={employee.props.eMail}
                                            image={employee.props.image}
                                            job={employee.props.job}
                                            mobileNumber={employee.props.mobileNumber}/>
                                    </Padding>
                                )
                            } else {
                                return (
                                    <EmployeeColumnContainer key={employee.props.name}>
                                        <Padding bottom={50}>
                                            <Contact
                                                name={employee.props.name}
                                                eMail={employee.props.eMail}
                                                image={employee.props.image}
                                                job={employee.props.job}
                                                mobileNumber={employee.props.mobileNumber}/>
                                        </Padding>
                                    </EmployeeColumnContainer>
                                )
                            }
                        })

                    }
                </>
            )
        }
        return null
    }

    return (
        <ContentContainer>
            {
                content.title &&
                <Padding bottom={50} top={100}>
                    <Heading1 title={content.title} textColor={Colors.red}/>
                </Padding>
            }
            <Grid colsLaptop={colsLaptop} colsTablet={colsTablet} cols={1} gap={12}>
                <EmployeeToRender/>
            </Grid>

        </ContentContainer>
    )
}

export default EmployeeBlock
