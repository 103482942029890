import * as React from "react";
import {useEffect, useState} from "react";
import {PlaceholderContainer, TextContainer, TextPlaceholderContainer} from "./anchor-navigation-block.styled";
import {Padding} from "../../padding/padding.styled";

interface IAnchorElementsBlock {
    title: string,
    linkTo?: string
}

interface IAnchorNavigationBlock {
    anchorElements: IAnchorElementsBlock[]
}

const AnchorNavigationBlock = (content: IAnchorNavigationBlock) => {
    const url = typeof window !== 'undefined' ? window.location.origin : '';
    const scrollY = typeof window !== 'undefined' ? window.scrollY : 0;
    const anchorEntryLength = content.anchorElements.length;

    const [offset, setOffset] = useState(0)
    useEffect(() => {
        if (typeof window !== `undefined`) {
            window.onscroll = () => {
                const rect = document.getElementById("anchor")?.getBoundingClientRect().top;
                setOffset(rect ?? 0)
            }
        }
    }, [])

    return (
        <>
            {content.anchorElements &&
            <Padding top={100}>
                <div id={"anchor"} className={offset < 20 && scrollY > 0 ? 'fix-label' : ''}>
                    <TextContainer>
                        {content.anchorElements.map((block, index) => {
                            return (
                                <TextPlaceholderContainer key={block.title}>
                                    {
                                        block.linkTo ?
                                            <a href={`${url}${block.linkTo}`}>
                                                {block.title}
                                            </a>
                                            : <>{block.title}</>
                                    }
                                    <PlaceholderContainer>
                                        {anchorEntryLength - 1 != index ? '/' : ''}
                                    </PlaceholderContainer>
                                </TextPlaceholderContainer>
                            )
                        })}
                    </TextContainer>
                </div>
            </Padding>
            }
        </>
    )
}

export default AnchorNavigationBlock
