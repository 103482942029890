import * as React from "react"
import {Title} from "./title-big.styled";
import {Colors} from "../../utils/colors";

export interface ITitleBig {
    title: string,
    textColor?: string,
    textAlign?: string,
    textAlignLaptop?: string,
}

const TitleBig = (content: ITitleBig) => {
    return (
        <Title
            textColor={content.textColor ?? Colors.white}
            textAlign={content.textAlign ?? 'center'}
            textAlignLaptop={content.textAlignLaptop ?? 'left'}>
            {content.title}
        </Title>
    )
}

export default TitleBig
