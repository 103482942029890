import styled from "styled-components";
import {Colors} from "../../utils/colors";
import {Breakpoints} from "../../utils/breakpoints";

export const PageNavigationContainer = styled.div.attrs({
    className: "flex flex-row items-center justify-center relative tablet:absolute"
})<{ isCentered?: boolean }>`
  z-index: 3;
  margin-top: 0;
  margin-right: -1px;
  bottom: 0;
  cursor: default;
  background: ${Colors.white};
  align-items: center;
  height: 70px;

  @media ${Breakpoints.tablet} {
    right: ${props => props.isCentered ? 'unset' : 0};
    width: 200px;
  }
`

export const ArrowIconContainer = styled.div<{ color?: string }>`
  cursor: pointer;

  svg {
    color: ${props => props.color ? props.color : Colors.red};
  }
`
