import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import * as React from "react";
import {AppStoreContainer, AppStoreLink, Container, ImageContainer} from "./app-store-info-block.styled";
import ContentContainer from "../../content-container/content-container";
import {Padding} from "../../padding/padding.styled";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import IosStoreLogo from "/src/assets/svg/download_ios_de.svg";
import AndroidStoreLogo from "/src/assets/svg/download_android_de.svg";
import WebPlatformLogo from "/src/assets/svg/button_webapp.svg";
import {Content} from "../../two-text-columns/two-text-columns.styled";
import {Colors} from "../../../utils/colors";
import Heading1 from "../../heading-1/heading-1";
import Heading2 from "../../heading-2/heading-2";

interface IAppStoreInfoBlock {
    header: string,
    title: string,
    subtitle: string,
    iosLink: string,
    androidLink: string,
    webLink: string,
    image: {
        localFile: FileNode
    },
}

const AppStoreInfoBlock = (props: IAppStoreInfoBlock) => {
    const image = getImage(props.image.localFile)

    return (
        <Padding topLaptop={120} top={30}>
            <ContentContainer>
                <Container>
                    <Padding topLaptop={100} top={20} bottom={150} rightLaptop={500} right={0}>
                        <Heading1 title={props.header} textColor={Colors.black}/>
                        <Padding top={40}>
                            <Heading2 title={props.title} textColor={Colors.black}/>
                        </Padding>
                        <Padding top={60}>
                            <Content color={Colors.black}>{props.subtitle}</Content>
                        </Padding>
                        {
                            image &&
                            <ImageContainer>
                                <GatsbyImage image={image} alt="tbd"/>
                            </ImageContainer>
                        }
                        <Padding top={60}>
                            <AppStoreContainer>
                                <AppStoreLink href={props.iosLink} target="_blank">
                                    <IosStoreLogo/>
                                </AppStoreLink>
                                <AppStoreLink href={props.androidLink} target="_blank">
                                    <AndroidStoreLogo/>
                                </AppStoreLink>
                                <AppStoreLink href={props.webLink} target="_blank">
                                    <WebPlatformLogo/>
                                </AppStoreLink>
                            </AppStoreContainer>
                        </Padding>
                    </Padding>
                </Container>
            </ContentContainer>
        </Padding>
    )
}

export default AppStoreInfoBlock
