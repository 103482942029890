import * as React from "react"
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {ColumnGrid, GatsbyImageContainer, ColumnContainer} from "./contact-with-description.styled";
import {getImage} from "gatsby-plugin-image";
import Heading3 from "../heading-3/heading-3";
import {Colors} from "../../utils/colors";
import {Mail, MobileNumber} from "../../utils/phone-email-link";
import Heading2 from "../heading-2/heading-2";
import {Content} from "../blocks/title-subtile-grid-block/title-subtitle-grid-block.styled";
import {Padding} from "../padding/padding.styled";

export interface IContactWithDescription {
    eMail: string
    job: string,
    mobileNumber: string,
    name: string,
    description: string
    image: {
        localFile: FileNode
    }
}

const ContactWithDescription = (content: IContactWithDescription) => {
    const image = getImage(content.image?.localFile)
    return (
        <ColumnGrid>
            <ColumnContainer leftColumn={true}>
                <Padding top={12}>
                    <p>
                        {content.job.toUpperCase()}
                    </p>
                    <Heading2 title={content.name} textColor={Colors.black}/>

                    <Padding top={20}>
                        <Content color={Colors.grey}>{content.description}</Content>
                    </Padding>
                </Padding>
            </ColumnContainer>
            <ColumnContainer leftColumn={false}>
                <Padding top={12}>
                    {
                        image &&
                        <GatsbyImageContainer image={image} alt="employee-image"/>
                    }
                    <Padding top={20}>
                        <a href={MobileNumber.Number(content.mobileNumber)}>
                            <Heading3 title={content.mobileNumber} textColor={Colors.black} textAlign={"end"}/>
                        </a>
                    </Padding>
                    <a href={Mail.Link(content.eMail)}>
                        <Heading3 title={content.eMail} textColor={Colors.black} textAlign={"end"}/>
                    </a>
                </Padding>
            </ColumnContainer>
        </ColumnGrid>
    )
}

export default ContactWithDescription
