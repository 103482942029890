import * as React from "react"
import {useState} from "react"
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import PageNavigationControl from "../../page-navigation-control/page-navigation-control";
import {GatsbyImage, getImage, IGatsbyImageData} from "gatsby-plugin-image";
import {Padding} from "../../padding/padding.styled";
import Overlay from "/src/assets/svg/overlay_projekte_gespiegelt.svg";
import {ImageContainer, OverlayContainer, ThumbnailImageEntry, ThumbnailRow} from "./image-carousel-block.styled";

export interface IImageCarouselBlock {
    images: {
        image: {
            localFile: FileNode
        }
    }[],
    thumbnails: {
        image: {
            localFile: FileNode
        }
    }[]
}

const ThumbnailImage = (key: string, isSelected: boolean, onTap: () => void, thumbnail?: IGatsbyImageData) => {
    if (thumbnail) {
        return (
            <Padding left={5} right={5} top={5} bottom={5} key={key}>
                <ThumbnailImageEntry image={thumbnail} alt="project-image-thumbnail" isSelected={isSelected}
                                     onClick={onTap}/>
            </Padding>
        )
    }
    return (<></>)
}

const ImageCarouselBlock = (content: IImageCarouselBlock) => {
    const [imageIndex, setImageIndex] = useState(0);

    function nextImage(): void {
        let newIndex = (imageIndex + 1) % content.images.length;
        setImageIndex(newIndex);
    }

    function previousImage(): void {
        let newIndex = imageIndex - 1;
        if (newIndex < 0) {
            newIndex = content.images.length - 1;
        }

        setImageIndex(newIndex);
    }

    const image = getImage(content.images[imageIndex].image.localFile);

    return (
        <>
            <Padding top={50}>
                <ImageContainer>
                    <OverlayContainer>
                        <Overlay/>
                    </OverlayContainer>
                    {
                        image &&
                        <GatsbyImage image={image} alt="project-image"/>
                    }
                    <PageNavigationControl
                        title={`${imageIndex + 1}/${content.images.length}`}
                        onBack={() => previousImage()}
                        onNext={() => nextImage()}/>
                </ImageContainer>
            </Padding>
            <Padding topLaptop={20} top={0} right={50} left={50} rightLaptop={100} leftLaptop={100}>
                <ThumbnailRow>
                    {
                        content.thumbnails.map((thumbnail, index) =>
                            ThumbnailImage(
                                'project-image-' + index,
                                index === imageIndex,
                                () => setImageIndex(index),
                                getImage(thumbnail.image.localFile))
                        )
                    }
                </ThumbnailRow>
            </Padding>
        </>
    )
}

export default ImageCarouselBlock
