import * as React from "react"
import TitleBig from "../../title-big/title-big";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {
    HeaderBackground,
    HeaderContainer,
    LandingImage,
    LandingImageMobile,
    SubTitle,
    SubTitlePadding
} from "./landing-title-header-block.styled";
import {Padding} from "../../padding/padding.styled";
import ActionBorderButton from "../../action-border-button/action-border-button";
import {Colors} from "../../../utils/colors";
import ContentContainer from "../../content-container/content-container";

interface ILandingTitleHeaderBlockProps {
    title1: string,
    title2: string,
    description: string,
    buttonText: string,
    buttonLink: string,
    image: {
        localFile: FileNode
    },
    imageMobile: {
        localFile: FileNode
    }
}

const LandingTitleHeaderBlock = (props: ILandingTitleHeaderBlockProps) => {
    const image = getImage(props.image.localFile);
    const imageMobile = getImage(props.imageMobile.localFile);
    return (
        <HeaderContainer>
            <HeaderBackground/>
            <Padding left={30} rightLaptop={450} right={30} topLaptop={250} top={130}>
                <TitleBig title={props.title1.toUpperCase()}/>
                <SubTitlePadding>
                    <TitleBig title={props.title2.toUpperCase()}/>
                </SubTitlePadding>
            </Padding>
            {
                imageMobile &&
                <LandingImageMobile>
                    <Padding top={50}>
                        <GatsbyImage image={imageMobile} alt="header-image-mobile"/>
                    </Padding>
                </LandingImageMobile>
            }
            <Padding topLaptop={100} top={40} left={30} rightLaptop={450} right={30}>
                <ContentContainer onlyForMobile={true}>
                    <SubTitle>{props.description}</SubTitle>
                    <Padding top={30}>
                        <ActionBorderButton
                            title={props.buttonText}
                            buttonLink={props.buttonLink}
                            color={Colors.white}
                            hoverColor={Colors.red}
                            hoverBackgroundColor={Colors.white}
                            alignButton={"flex-end"}
                            alignButtonMobile={"stretch"}
                        />
                    </Padding>
                </ContentContainer>
            </Padding>
            {
                image &&
                <LandingImage>
                    <GatsbyImage image={image} alt="header-image"/>
                </LandingImage>
            }
        </HeaderContainer>
    )
}

export default LandingTitleHeaderBlock
