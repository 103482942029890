import styled from "styled-components";
import {FontSizes} from "../../utils/font-sizes";

export const Title = styled.h3<{ textAlign: string, textColor: string }>`
  text-align: ${props => props.textAlign};
  font-size: ${FontSizes.size16};
  color: ${props => props.textColor};
  font-weight: 600;
  line-height: 20px;
`
