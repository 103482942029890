import * as React from "react"
import {Colors} from "../../utils/colors";
import {Title} from "./heading-2.styled";

export interface IHeading2 {
    title: string,
    hasHyphens?: boolean,
    textAlign?: string,
    textColor?: string
}

const Heading2 = (content: IHeading2) => {
    return (
        <Title
            hasHyphens={content.hasHyphens ?? false}
            textAlign={content.textAlign ?? 'start'}
            textColor={content.textColor ?? Colors.white}>
            {content.title}
        </Title>
    )
}

export default Heading2
