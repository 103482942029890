import * as React from "react"
import {Content, Grid} from "./two-text-columns.styled";

export interface ITwoTextColumns {
    first: string,
    second: string
}

const TwoTextColumns = (content: ITwoTextColumns) => {
    return (
        <Grid>
            <Content>{content.first}</Content>
            <Content>{content.second}</Content>
        </Grid>
    )
}

export default TwoTextColumns
