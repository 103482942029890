import {ReactNode} from "react";
import styled from "styled-components";
import {Breakpoints} from "../../utils/breakpoints";

export interface IPadding {
    topLaptop?: number,
    topTablet?: number;
    top?: number,
    bottomLaptop?: number,
    bottomTablet?: number;
    bottom?: number,
    leftLaptop?: number,
    leftTablet?: number;
    left?: number,
    rightLaptop?: number,
    rightTablet?: number;
    right?: number,
    children: ReactNode
}

export const Padding = styled.div<IPadding>`
  padding-top: ${props => props.top ?? 0}px;
  padding-bottom: ${props => props.bottom ?? 0}px;
  padding-left: ${props => props.left ?? 0}px;
  padding-right: ${props => props.right ?? 0}px;

  @media ${Breakpoints.tablet} {
    padding-top: ${props => props.topTablet ?? props.top ?? 0}px;
    padding-bottom: ${props => props.bottomTablet ?? props.bottom ?? 0}px;
    padding-left: ${props => props.leftTablet ?? props.left ?? 0}px;
    padding-right: ${props => props.rightTablet ?? props.right ?? 0}px;
  }

  @media ${Breakpoints.laptop} {
    padding-top: ${props => props.topLaptop ?? props.top ?? 0}px;
    padding-bottom: ${props => props.bottomLaptop ?? props.bottom ?? 0}px;
    padding-left: ${props => props.leftLaptop ?? props.left ?? 0}px;
    padding-right: ${props => props.rightLaptop ?? props.right ?? 0}px;
  }
`;
