import styled from "styled-components";
import {FontSizes} from "../../../utils/font-sizes";
import {Breakpoints} from "../../../utils/breakpoints";
import {Colors} from "../../../utils/colors";

export const Title = styled.h1`
  font-size: ${FontSizes.size30};
  color: ${Colors.greyDark};
  font-weight: 600;
  line-height: 0.8;
  hyphens: none;

  @media ${Breakpoints.tablet} {
    font-size: ${FontSizes.size48};
  }
`

export const JobEntryRow = styled.div.attrs({
    className: "flex flex-row items-center justify-between"
})`
  cursor: pointer;
  @media ${Breakpoints.tablet} {
    svg {
      transform: scale(1.3);
    }
  }
`

export const JobEntryTitle = styled.h2`
  color: ${Colors.greyDark};
  font-size: ${FontSizes.size16};
  font-weight: 500;
  text-align: left;

  @media ${Breakpoints.tablet} {
    font-size: ${FontSizes.size22};
  }
`

export const Footer = styled.p`
  line-height: 30px;
  font-size: ${FontSizes.size22};
  font-weight: 600;
  color: ${Colors.red};
`

export const ArrowContainer = styled.div`
  flex: 0 0 20px;
`
