import * as React from "react";
import ContentContainer from "../../content-container/content-container";
import {Padding} from "../../padding/padding.styled";
import {Colors} from "../../../utils/colors";
import Heading2 from "../../heading-2/heading-2";
import {Content, TextContainer} from "./title-subtitle-grid-block.styled";
import Heading3 from "../../heading-3/heading-3";
import Heading1 from "../../heading-1/heading-1";

interface ITextBlock {
    textTitle: string,
    textSubtitle: string,
    textDescription: string,
}

interface ITitleSubtitleGridBlock {
    header: string,
    title: string,
    textBlocks: ITextBlock[]
}

const TitleSubtitleGridBlock = (props: ITitleSubtitleGridBlock) => {
    const paddingBottom = props.textBlocks ? 80 : 0
    return (
        <Padding topLaptop={100} top={50} bottom={0} bottomLaptop={paddingBottom}>
            <ContentContainer>
                <Padding rightLaptop={200} right={0}>
                    <span id={props.header.replace(" ", "")}>
                        <Heading1 title={props.header} textColor={Colors.greyDark}/>
                    </span>
                    <Padding top={40} bottomLaptop={80} bottom={40}>
                        <Heading2 title={props.title} textColor={Colors.black}/>
                    </Padding>
                </Padding>
                <TextContainer>
                    {props.textBlocks && props.textBlocks.map((block, index) => {
                        return (
                            <div key={index}>
                                <Heading3 title={block.textTitle} textColor={Colors.black}/>
                                <Heading2 title={block.textSubtitle} textColor={Colors.black}/>
                                <Content color={Colors.grey}>{block.textDescription}</Content>
                            </div>
                        )
                    })}
                </TextContainer>
            </ContentContainer>
        </Padding>
    )
}

export default TitleSubtitleGridBlock
