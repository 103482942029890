import styled from "styled-components";
import {FontSizes} from "../../utils/font-sizes";

export const Title = styled.h2<{ textAlign: string, textColor: string, hasHyphens: boolean }>`
  text-align: ${props => props.textAlign};
  color: ${props => props.textColor};
  font-size: ${FontSizes.size22};
  hyphens: ${props => props.hasHyphens ? 'auto' : 'none'};
  line-height: 38px;
  font-weight: 500;
`
