import ContentContainer from "../../content-container/content-container";
import * as React from "react";
import ActionBorderButton from "../../action-border-button/action-border-button";
import {Padding} from "../../padding/padding.styled";
import {
    ActionBorderButtonContainer,
    HeaderContainer,
    BlockContainer,
    BackgroundContainer,
    SubheaderContainer
} from "./title-action-block.styled";

interface ITitleActionBlock {
    title: string,
    subtitle: string,
    actionBorderButtonTitle: string,
    backgroundColor: string,
    smallerTextForMobile: boolean
    actionButtonLink: string
}

const TitleActionBlock = (content: ITitleActionBlock) => {
    return (
        <ContentContainer>
            <BlockContainer>
                <BackgroundContainer backgroundColor={content.backgroundColor}/>
                    <Padding top={80}>
                        <HeaderContainer sameSizeForMobile={content.smallerTextForMobile}>
                            {content.title}
                        </HeaderContainer>
                        <SubheaderContainer>
                            {content.subtitle}
                        </SubheaderContainer>
                    </Padding>

                    <Padding top={40} rightLaptop={600} right={0} bottom={80}>
                        <ActionBorderButtonContainer>
                            <ActionBorderButton title={content.actionBorderButtonTitle}
                                                alignButtonMobile={"stretch"}
                                                alignButton={"stretch"}
                                                buttonLink={content.actionButtonLink}/>
                        </ActionBorderButtonContainer>
                    </Padding>
            </BlockContainer>
        </ContentContainer>
    )
}

export default TitleActionBlock
