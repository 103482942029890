import * as React from "react"
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {Padding} from "../../padding/padding.styled";
import ContentContainer from "../../content-container/content-container";
import {ActionBorderButtonContainer, HeaderContainer} from "../title-action-block/title-action-block.styled";
import ActionBorderButton from "../../action-border-button/action-border-button";
import {GreyBackground, ImageContainer, Row, TitleActionContainer} from "./image-title-action-block.styled";

export interface IImageTitleActionBlock {
    image: {
        localFile: FileNode
    },
    title: string,
    actionBorderButtonTitle: string,
    actionButtonLink: string
}

const ImageTitleActionBlock = (content: IImageTitleActionBlock) => {
    const image = getImage(content.image.localFile);
    return (
        <ContentContainer onlyForMobile={true}>
            <Padding topLaptop={150} top={80}>
                <Row>
                    <ImageContainer>
                        <Padding rightLaptop={100} right={20} left={20}>
                            {
                                image &&
                                <GatsbyImage image={image} objectFit={"contain"} alt="project-action-image"/>
                            }
                        </Padding>
                    </ImageContainer>

                    <TitleActionContainer>
                        <Padding topLaptop={160} top={100}>
                            <GreyBackground>
                                <ContentContainer>
                                    <Padding topLaptop={100} top={50} left={15} right={15}>
                                        <HeaderContainer>
                                            {content.title}
                                        </HeaderContainer>
                                    </Padding>

                                    <Padding topLaptop={80} top={40} rightLaptop={200} right={15} left={15} bottom={50} bottomLaptop={100}>
                                        <ActionBorderButtonContainer>
                                            <ActionBorderButton title={content.actionBorderButtonTitle}
                                                                alignButtonMobile={"stretch"}
                                                                alignButton={"stretch"}
                                                                buttonLink={content.actionButtonLink}/>
                                        </ActionBorderButtonContainer>
                                    </Padding>
                                </ContentContainer>
                            </GreyBackground>
                        </Padding>
                    </TitleActionContainer>
                </Row>
            </Padding>
        </ContentContainer>
    )
}

export default ImageTitleActionBlock
