import * as React from "react"
import {
    HeaderContainer,
    IconTextBackground,
    IconTextBorder,
    IconTextContainer,
    IconTextContent,
    IconTextImage,
    IconTextWrapper
} from "./icon-text.styled";
import {Padding} from "../padding/padding.styled";
import ContentContainer from "../content-container/content-container";
import Heading2 from "../heading-2/heading-2";
import {Colors} from "../../utils/colors";
import Heading1 from "../heading-1/heading-1";

interface IIconTextBlock {
    title: string,
    description: string,
    hasBorder: boolean,
    linkTo?: string,
    icon: {
        localFile: {
            url: string
        },
    }
}

export interface IIconTextBlocks {
    heading?: string,
    iconTextBlocks: IIconTextBlock[]
}

const IconText = (content: IIconTextBlocks) => {

    const Border = (props: { hasBorder: boolean, index: number }) => {
        if (props.hasBorder) {
            return props.index % 2 == 0 ? <IconTextBorder className={'even'}/> :
                <IconTextBorder className={'odd'}/>
        }
        return null
    }

    return (
        <Padding top={30}>
            <ContentContainer onlyForMobile={true}>
                <Padding bottom={50} rightLaptop={110} right={0}>
                    <HeaderContainer>
                        {content.heading &&
                        <Heading2 textColor={Colors.black} textAlign={"right"} title={content.heading}/>}
                    </HeaderContainer>
                </Padding>
                <IconTextContainer cols={content.iconTextBlocks.length}>
                    {content.iconTextBlocks && content.iconTextBlocks.map((block, index) => {
                        const IconTextBlockChildren = (
                            <IconTextBackground>
                                <Border hasBorder={block.hasBorder} index={index}/>
                                <IconTextContent>
                                    <Padding topLaptop={60} top={30} bottom={40}>
                                        <IconTextWrapper>
                                            {
                                                block.icon.localFile.url &&
                                                <IconTextImage src={block.icon.localFile.url} alt="tbd"/>
                                            }
                                            <Heading1 title={block.title} textColor={Colors.red}/>
                                            <p className={'mt-2'}>
                                                {block.description}
                                            </p>
                                        </IconTextWrapper>
                                    </Padding>
                                </IconTextContent>
                            </IconTextBackground>
                        )

                        return (
                            block.linkTo ?
                                <a key={block.title}
                                   href={`${block.linkTo}`}>{IconTextBlockChildren}</a>
                                : <div key={block.title}>{IconTextBlockChildren}</div>
                        )
                    })}
                </IconTextContainer>
            </ContentContainer>
        </Padding>
    )
}

export default IconText
