import styled from "styled-components";
import {FontSizes} from "../../../utils/font-sizes";
import {Breakpoints} from "../../../utils/breakpoints";

export const Container = styled.div`
  position: static;
  word-break: break-word;

  @media ${Breakpoints.laptop} {
    position: ${"relative"};
  }
`

export const AppStoreContainer = styled.div.attrs({
    className: 'grid grid-cols-1 tablet:grid-cols-3 laptop:grid-cols-3'
})`
  gap: 20px;
  position: ${"relative"};
  max-width: 620px;

  @media ${Breakpoints.tablet} {
    gap: 10px;
  }
  
  @media ${Breakpoints.laptop} {
    position: ${"absolute"};
    left: 0;
    gap: 20px;
  }
`

export const Title = styled.div`
  font-size: ${FontSizes.size22};
  font-weight: 600;
  line-height: 38px;
`

export const AppStoreLink = styled.a.attrs({
    className: 'flex justify-center'
})`
  cursor: pointer;
  
  svg {
    width: 180px;
    height: auto;
  }
`

export const ImageContainer = styled.div`
  position: static;
  right: -175px;
  top: 0;
  padding-top: 70px;
 
  @media ${Breakpoints.laptop} {
    position: ${"absolute"};
    padding-top: 0;
  }

`
