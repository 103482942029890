import * as React from "react"
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Heading3 from "../heading-3/heading-3";
import {Colors} from "../../utils/colors";
import Heading2 from "../heading-2/heading-2";
import {Padding} from "../padding/padding.styled";
import {Mail, MobileNumber} from "../../utils/phone-email-link";

export interface IContact {
    eMail: string
    job: string,
    mobileNumber: string,
    name?: string,
    image?: {
        localFile: FileNode
    }
}

const Contact = (content: IContact) => {
    const image = content.image ? getImage(content.image.localFile) : null
    const contactAlign = image ? 'end' : 'start'
    const extraPaddingWithImage = image ? 10 : 0
    return (
        <>
            <p>
                {content.job.toUpperCase()}
            </p>
            <Padding bottom={extraPaddingWithImage}>
                <Heading2 title={content.name ?? ''} textColor={Colors.black}/>
            </Padding>

            {
                image &&
                <GatsbyImage image={image} alt="employee-image"/>

            }
            <Padding top={10 + extraPaddingWithImage} bottom={10 - extraPaddingWithImage}>
                <a href={MobileNumber.Number(content.mobileNumber)}>
                    <Heading3 title={content.mobileNumber} textColor={Colors.black} textAlign={contactAlign}/>
                </a>
            </Padding>
            <a href={Mail.Link(content.eMail)}>
                <Heading3 title={content.eMail} textColor={Colors.black} textAlign={contactAlign}/>
            </a>
        </>
    )
}

export default Contact
