import styled from "styled-components";
import {FontSizes, FontSizesBorder} from "../../utils/font-sizes";
import {Breakpoints} from "../../utils/breakpoints";

export const Column = styled.div.attrs({
    className: "flex flex-col"
})<{ alignItems?: string, alignItemsMobile?: string }>`
  align-items: ${props => props.alignItemsMobile ?? props.alignItems ?? 'center'};

  @media ${Breakpoints.laptop} {
    align-items: ${props => props.alignItems ?? 'center'};
  }
`

export const ClickArea = styled.a`
  cursor: pointer;
`

export const Title = styled.div.attrs({
    className: "flex items-center justify-center"
})<{ color: string, hoverBackgroundColor: string, hoverColor: string }>`
  color: ${props => props.color};
  font-size: ${FontSizes.size20};
  font-weight: 700;
  border: ${FontSizesBorder.size2Solid} ${props => props.color};
  height: 50px;
  border-radius: 2px;
  
  &:focus{
    filter: contrast(90%);
    background: ${props => props.hoverBackgroundColor};
    color: ${props => props.hoverColor};
    outline: none;
  }
  
  &:hover {
    background: ${props => props.hoverBackgroundColor};
    color: ${props => props.hoverColor};
  }

  @media ${Breakpoints.tablet} {
    padding: 0 50px;
  }
`
