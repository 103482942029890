import styled from "styled-components";
import {Breakpoints} from "../../../utils/breakpoints";
import {Colors} from "../../../utils/colors";
import {GatsbyImage} from "gatsby-plugin-image";
import * as React from "react";

export const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;

  @media ${Breakpoints.laptop} {
    height: 800px;
  }
`

export const OverlayContainer = styled.div`
  display: none;

  @media ${Breakpoints.laptop} {
    display: initial;
    position: absolute;
    width: 100%;
    height: 164px;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

export const ThumbnailRow = styled.div.attrs({
    className: "flex-row flex-wrap hidden justify-center tablet:flex laptop:justify-start"
})`
`

export const ThumbnailImageEntry = styled(({isSelected: boolean, ...props}) => <GatsbyImage {...props} />)`
  cursor: pointer;
  border: ${props => "3px solid " + (props.isSelected ? Colors.red : Colors.white)};
`
